exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/Category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-coming-soon-jsx": () => import("./../../../src/templates/ComingSoon.jsx" /* webpackChunkName: "component---src-templates-coming-soon-jsx" */),
  "component---src-templates-food-collection-jsx": () => import("./../../../src/templates/FoodCollection.jsx" /* webpackChunkName: "component---src-templates-food-collection-jsx" */),
  "component---src-templates-hero-product-jsx": () => import("./../../../src/templates/HeroProduct.jsx" /* webpackChunkName: "component---src-templates-hero-product-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/News.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-privacy-cookie-policy-jsx": () => import("./../../../src/templates/PrivacyCookiePolicy.jsx" /* webpackChunkName: "component---src-templates-privacy-cookie-policy-jsx" */),
  "component---src-templates-products-root-jsx": () => import("./../../../src/templates/ProductsRoot.jsx" /* webpackChunkName: "component---src-templates-products-root-jsx" */),
  "component---src-templates-recurrence-jsx": () => import("./../../../src/templates/Recurrence.jsx" /* webpackChunkName: "component---src-templates-recurrence-jsx" */),
  "component---src-templates-simple-product-jsx": () => import("./../../../src/templates/SimpleProduct.jsx" /* webpackChunkName: "component---src-templates-simple-product-jsx" */),
  "component---src-templates-store-finder-jsx": () => import("./../../../src/templates/StoreFinder.jsx" /* webpackChunkName: "component---src-templates-store-finder-jsx" */)
}

