import { GatsbyImage } from 'gatsby-plugin-image/';
import styled from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ hasTopRoundedSection }) => hasTopRoundedSection && '-120px'};
  padding: var(--padding);
  position: relative;
  flex-direction: column;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1100px;
  align-items: center;

  @media screen and (max-width: 1060px) {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: var(--gap-4);
  }
`;

const Image = styled(GatsbyImage)``;

export const SectionTwoColsImg = ({ imageData, imgLeft, textBox }) => (
  <Wrapper>
    <Container>
      {imgLeft ? (
        <>
          <Image image={imageData} alt="" />
          {textBox}
        </>
      ) : (
        <>
          {textBox}
          <Image image={imageData} alt="" />
        </>
      )}
    </Container>
  </Wrapper>
);
