/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
import { GatsbyImage } from 'gatsby-plugin-image';
import { useRef } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';
import smoothscroll from 'smoothscroll-polyfill';
import { useInView } from 'react-intersection-observer';
import { useIntl } from 'react-intl';
import { useSize } from '../../../hooks/useSize';
import { Triangle } from '../../vectors/Symbols';
import { isSSR } from '../../../utils/misc';
import { NavigatorButtonNormal } from '../../ui/Buttons';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gap-3) var(--padding);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
  position: relative;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const SliderGrid = styled.div`
  display: grid;
  column-gap: 60px;
  grid-template-columns: ${({ slidesNumber }) =>
          `repeat(${slidesNumber}, minmax(200px, 1fr))`};
  justify-items: center;

  @media screen and (max-width: 810px) {
    column-gap: var(--gap-5);
    padding-bottom: var(--gap-5);
    grid-template-columns: ${({ slidesNumber }) =>
            `repeat(${slidesNumber}, calc(50vw - 40px))`};
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: ${({ slidesNumber }) =>
            `repeat(${slidesNumber}, calc(100vw - 40px))`};
  }
`;

const Slide = styled.article`
  display: grid;
  row-gap: var(--gap-6);
  justify-items: center;
  width: 100%;
  position: relative;
  align-items: end;

  & p {
    color: var(--blue-2);
    text-align: center;

    @media screen and (max-width: 810px) {
      font-size: var(--font-size-3);
      max-width: 90%;
    }
  }
`;

const Image = styled(GatsbyImage)`
  width: 200px;

  & img {
    opacity: 1 !important;
    transition: none !important;
  }
`;

const ForwardButton = styled.button`
  --width: 60px;
  position: absolute;
  left: ${({ back }) => back && '0'};
  right: ${({ forward }) => forward && '0'};
  top: calc(50% - var(--width));
  z-index: 3;
  height: var(--width);
  width: var(--width);
  border: none;
  padding: 0;
  border: 4px solid white;
  box-sizing: content-box;
  opacity: 1;
  border-radius: var(--width);
  background: #8693a1;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    position: relative;
    left: ${({ back }) => (back ? '-3px' : '3px')};
    width: calc(var(--width) / 2);
    height: calc(var(--width) / 2);
    transform: ${({ back }) => back && 'rotate(180deg)'};
  }

  @media screen and (max-width: 810px) {
    display: none;
  }
`;

const SlideWithZoom = ({
                         slideRef,
                         children,
                         heroProductRedirect,
                         recordId,
                         originalId,
                       }) => {
  const intl = useIntl();
  return (
    <Slide ref={slideRef}>
      {children}
      {heroProductRedirect === true ? (
        <NavigatorButtonNormal
          blue
          $bolder
          buttonText={intl.formatMessage({ id: 'discover' })}
          recordId={recordId}
        />
      ) : (
        <NavigatorButtonNormal
          blue
          $bolder
          buttonText={intl.formatMessage({ id: 'discover' })}
          recordId={originalId}
        />
      )}
    </Slide>
  );
};

export const ViewportSliderWithControls = ({
                                             productsArray
                                           }) => {
  const simpleBarContainer = useRef(null);
  const size = useSize(simpleBarContainer);
  let currentScroll = 0;
  let sliderCount = 0;
  const sliderWidth = size?.width;

  const [firsItemRef, firstItemInView] = useInView({
    threshold: 0.8,
    rootMargin: '4000px 0px 4000px 0px',
  });

  const [lastItemRef, lastItemInView] = useInView({
    threshold: 0.8,
    rootMargin: '4000px 0px 4000px 0px',
  });

  const scrollForward = () => {
    sliderCount += 1;
    currentScroll = sliderWidth * sliderCount;
    simpleBarContainer.current.scroll({
      left: currentScroll,
      behavior: 'smooth',
    });
  };

  const scrollBack = () => {
    sliderCount -= 1;
    currentScroll = sliderWidth * sliderCount;
    simpleBarContainer.current.scroll({
      left: currentScroll,
      behavior: 'smooth',
    });
  };
  if (!isSSR) smoothscroll.polyfill();

  return (
    <Wrapper>
      <Container id="viewport_slider">
        {!lastItemInView && (
          <ForwardButton forward onClick={scrollForward}>
            <Triangle back />
          </ForwardButton>
        )}
        {!firstItemInView && (
          <ForwardButton back onClick={scrollBack}>
            <Triangle back />
          </ForwardButton>
        )}
        <SimpleBar
          scrollableNodeProps={{ ref: simpleBarContainer }}
          forceVisible
          autoHide={false}
        >
          <SliderGrid slidesNumber={productsArray.length}>
            {productsArray.map(
              // eslint-disable-next-line max-len
              ({ mainImage: { gatsbyImageData }, titolo, productHeroReference, originalId }, index) => (
                <SlideWithZoom
                  key={originalId}
                  recordId={productHeroReference?.heroReferenceId}
                  originalId={originalId}
                  slideRef={
                    index === 0
                      ? firsItemRef
                      : index === productsArray.length - 1
                        ? lastItemRef
                        : null
                  }
                >
                  <Image image={gatsbyImageData} alt="" />
                  <p>{titolo}</p>
                </SlideWithZoom>
              ),
            )}
          </SliderGrid>
        </SimpleBar>
      </Container>
    </Wrapper>
  );
};
