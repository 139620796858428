/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { easeInOutSine, fadeInAnim, fadeOutAnim } from '../styles/animations';

export const Overlay = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: rgba(1, 16, 39, 0.6);
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: ${({ overlay }) => (overlay ? 'all' : 'none')};
  z-index: 4;
  visibility: ${({ overlay }) => overlay === null && 'hidden'};
  ${({ overlay }) =>
    overlay === true
      ? css`
          animation: ${fadeInAnim} 400ms ${easeInOutSine} forwards;
        `
      : overlay === false
      ? css`
          animation: ${fadeOutAnim} 400ms ${easeInOutSine} forwards;
        `
      : overlay === null
      ? null
      : null}
`;
