/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { HeadingSection, SubtitleSection } from '../../../styles/Typography';
import { ViewportSliderWithControls } from '../../sliders/ViewportSliderWithControls';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ index }) => index === 0 && '-90px'};
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  overflow: hidden;
`;

const SectionBackground = styled(GatsbyImage)`
  width: 100%;
  height: 460px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
  position: absolute;
  z-index: 1;

  @media screen and (max-width: 1280px) {
    width: 100%;
    padding: var(--padding);
  }

  @media screen and (max-width: 600px) {
    position: relative;
  }
`;

const TextBoxWrapper = styled.div`
  display: flex;
  position: relative;
  width: min-content;
`;

const Illustration = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 130px;
  position: relative;
  z-index: 2;
  top: 50px;

  @media screen and (max-width: 600px) {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: absolute;
    top: -50vw;
    right: 80px;
  }

  @media screen and (max-width: 360px) {
    display: none;
  }
`;

const RoundedTextBox = styled.div`
  background: var(--blue-2);
  border-radius: 100%;
  display: grid;
  row-gap: var(--gap-5);
  width: 400px;
  height: 400px;
  justify-items: start;
  align-content: center;
  padding: var(--gap-2);
  position: relative;
  left: -8%;

  @media screen and (max-width: 600px) {
    width: 110vw;
    height: 110vw;
    margin-top: -50vw;
  }

  @media screen and (max-width: 360px) {
    border-radius: 0;
    width: 100vw;
    height: 100%;
    padding: var(--gap-4);
    left: calc(var(--gap-5) * -1);
  }
`;

export const SectionRecurrenceIterable = ({
                                  backgroundImageData,
                                  categoryTitle,
                                  categoryDescription,
                                  illustrationUrl,
                                  index,
                                  productsArray,
                                  setOverlay,
                                  overlay,
                                  refArray,
                                }) => {
  return (
    <>
      <Wrapper
        index={index}
        refArray={refArray}
        ref={(ref) => (refArray.current[index] = ref)}
      >
        <SectionBackground image={backgroundImageData} alt="" />
        <Container>
          <TextBoxWrapper>
            <Illustration
              alt={categoryTitle}
              width={130}
              height={130}
              src={illustrationUrl}
            />
            <RoundedTextBox>
              <HeadingSection small>{categoryTitle}</HeadingSection>
              <SubtitleSection isXmas css={{ color: 'white' }}>
                {categoryDescription}
              </SubtitleSection>
            </RoundedTextBox>
          </TextBoxWrapper>
        </Container>
      </Wrapper>
      <ViewportSliderWithControls
        setOverlay={setOverlay}
        overlay={overlay}
        productsArray={productsArray}
      />
    </>
  );
};
