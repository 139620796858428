import { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
import { HeroClip } from '../components/layout/sections/SectionHero';
import { PageWrapper } from '../components/layout/PageWrapper';
import { SectionSlider } from '../components/layout/sections/SectionSlider';
import { SectionTwoColsImg } from '../components/layout/sections/SectionTwoColsImg';
import { SectionRecurrenceIterable } from '../components/layout/sections/recurrences/SectionRecurrenceIterable';
import { SectionRecurrenceShowcase } from '../components/layout/sections/recurrences/SectionRecurrenceShowcase';
import { AboutTextBox } from '../components/misc/HeroContent';
import {
  HeadingSection,
  SubtitleSection,
  TextBoxGeneric,
} from '../components/styles/Typography';
import { Overlay } from '../components/misc/Overlay';

const Recurrence = ({
                     data: {
                       datoCmsRicorrenze: {
                         heroImage: { heroImageData },
                         heroImageMedium: { heroMediumImageData },
                         titleSectionOne,
                         productsSectionOne,
                         titleSectionTwo,
                         subtitleSectionTwo,
                         imageSectionTwo: { sectionTwoImageData },
                         titleSectionThree,
                         recurrenceCategories,
                         heroText,
                         heroTextColor: { hex: heroTextColorHex},
                         heroSubtitle,
                         heroSubtitleColor: { hex: heroSubtitleColorHex},
                       },
                     },
                   }) => {
  const directedImages = withArtDirection(getImage(heroImageData), [
    {
      media: '(max-width: 810px)',
      image: getImage(heroMediumImageData),
    },
  ]);
  const [overlay, setOverlay] = useState(null);
  const sectionRefs = useRef([]);

  return (
    <>
      <Overlay overlay={overlay} />
      <HeroClip
        scale
        maxHeight="850px"
        overlay
        clipHeight="800px"
        $minHeightMobile="700px"
        $gatsbyImageMaxHeight="800px"
        imageData={directedImages}
        contentBox={(
          <AboutTextBox
            heroTitle={heroText}
            heroTitleColor={heroTextColorHex}
            heroSubtitle={heroSubtitle}
            heroSubtitleColor={heroSubtitleColorHex}
            paddingBottom="140px"
          />
        )}
      />
      <SectionSlider
        sectionTitle={titleSectionOne}
        productsArray={productsSectionOne}
      />
      <SectionTwoColsImg
        imageData={sectionTwoImageData}
        textBox={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <TextBoxGeneric rowGap="var(--gap-5)">
            <HeadingSection blueHeading>{titleSectionTwo}</HeadingSection>
            <SubtitleSection>{subtitleSectionTwo}</SubtitleSection>
          </TextBoxGeneric>
        }
      />
      <SectionRecurrenceShowcase
        refArray={sectionRefs}
        sectionTitle={titleSectionThree}
        categoriesArray={recurrenceCategories}
      />
      {recurrenceCategories.map(
        (
          {
            id,
            categoryTitle,
            description,
            backgroundImage: { categoryImageData },
            categoryImage: { url },
            products,
          },
          index,
        ) => (
          <SectionRecurrenceIterable
            index={index}
            key={id}
            refArray={sectionRefs}
            categoryTitle={categoryTitle}
            categoryDescription={description}
            backgroundImageData={categoryImageData}
            illustrationUrl={url}
            productsArray={products}
            setOverlay={setOverlay}
            overlay={overlay}
          />
        ),
      )}
    </>
  );
};

const RecurrenceTemplate = ({
 data,
 pageContext,
}) => {
  const { datoCmsRicorrenze: { seo } } = data;
  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <Recurrence data={data} />
    </PageWrapper>
  );
};
export default RecurrenceTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!){
    datoCmsRicorrenze(originalId: { eq: $id }, locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      heroImage {
        heroImageData: gatsbyImageData
      }
      heroImageMedium {
        heroMediumImageData: gatsbyImageData
      }
      heroText
      heroTextColor {
        hex
      }    
      heroSubtitle
      heroSubtitleColor {
        hex
      }
      titleSectionOne
      productsSectionOne {
      ... on DatoCmsProdotti {
        originalId
        titolo
        homepageSliderQuote
        sliderDescription
        heroProductRedirect
        productHeroReference {
            heroReferenceId: originalId
          }
        mainImage {
            gatsbyImageData(aspectRatio: 1, placeholder: NONE)
          }
      }
      ... on DatoCmsHeroProduct {
        originalId
        titolo
        homepageSliderQuote
        sliderDescription
        mainImage {
            gatsbyImageData(aspectRatio: 1, placeholder: NONE)
          }
      }
      }
      titleSectionTwo
      subtitleSectionTwo
      imageSectionTwo {
        sectionTwoImageData: gatsbyImageData
      }
      titleSectionThree
      recurrenceCategories {
        id: originalId
        categoryTitle
        categoryImage {
          url
          width
          height
        }
        categoryImageJpg {
          gatsbyImageData
        }
        description
        backgroundImage {
          categoryImageData: gatsbyImageData
        }
      products {
        ... on DatoCmsProdotti {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          heroProductRedirect
          productHeroReference {
            originalId
            productHeroSlug: slug
            productCategory {
              productHeroCategorySlug: slug
            }
          }
          mainImage {
            gatsbyImageData(placeholder: NONE, aspectRatio: 1)
          }
        }
        ... on DatoCmsHeroProduct {
          originalId
          titolo
          slug
          sliderDescription
          productCategory {
            categorySlug: slug
          }
          mainImage {
            gatsbyImageData(aspectRatio: 1, placeholder: NONE)
          }
        }
      }
      }
    }
  }
`;
