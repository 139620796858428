import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { easeInOutSine } from '../../../styles/animations';
import { HeadingSection } from '../../../styles/Typography';
import { SvgWaveBottomStatic, SvgWaveTop } from '../../../vectors/Waves';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
`;

const BackgroundContainer = styled.div`
  background: var(--blue-2);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--padding);
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1280px;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const Grid = styled.div`
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  column-gap: var(--gap-5);
  width: 100%;

  @media screen and (max-width: 810px) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: var(--gap-4);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Category = styled.section`
  display: grid;
  row-gap: var(--gap-5);
  justify-items: center;
  align-items: center;
  transition: transform ${easeInOutSine} 400ms;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const CategoryTitle = styled.h1`
  color: var(--foreground-white);
  font-size: var(--font-size);
  text-align: center;
  line-height: 1.2;
`;

export const SectionRecurrenceShowcase = ({
                                      sectionTitle,
                                      categoriesArray,
                                      refArray,
                                    }) => {
  const handleScroll = (index) => {
    setTimeout(() => {
      refArray.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 200);
  };

  return (
    <Wrapper>
      <SvgWaveTop waveFill="var(--blue-2)" />
      <BackgroundContainer>
        <Container>
          <HeadingSection
            css={{ maxWidth: '800px', marginBottom: 'var(--gap-3)' }}
          >
            {sectionTitle}
          </HeadingSection>
          <Grid>
            {categoriesArray.map(
              (
                { id, categoryImageJpg: { gatsbyImageData }, categoryTitle },
                index
              ) => (
                <Category onClick={() => handleScroll(index)} key={id}>
                  <GatsbyImage image={gatsbyImageData} alt={categoryTitle} />
                  <CategoryTitle>{categoryTitle}</CategoryTitle>
                </Category>
              )
            )}
          </Grid>
        </Container>
      </BackgroundContainer>
      <SvgWaveBottomStatic waveFill="var(--blue-2)" />
    </Wrapper>
  );
};
